import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@mui/material';

interface Bar {
  color: string;
  incidentId: string;
  date: string;
  severity: string;
}

interface HealthChartProps {
  name: string;
  data: Bar[];
  uptime: string;
  setHighlightActive: (active: boolean) => void;
}

const HealthChart: React.FC<HealthChartProps> = ({
  name,
  data,
  uptime,
  setHighlightActive,
}) => {
  const [hoveredBar, setHoveredBar] = useState<null | number>(null);
  const tooltipRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (hoveredBar !== null) {
      const tooltip = tooltipRefs.current[hoveredBar];
      if (tooltip) {
        const tooltipRect = tooltip.getBoundingClientRect();
        const overflowRight = tooltipRect.right > window.innerWidth;
        const overflowLeft = tooltipRect.left < 0;

        tooltip.style.transform = overflowRight
          ? 'translateX(-100%)'
          : overflowLeft
            ? 'translateX(0)'
            : 'translateX(-50%)';
      }
    }
  }, [hoveredBar]);

  return (
    <div
      style={{
        marginBottom: '40px',
        backgroundColor: '#fff',
        padding: '2px',
        boxSizing: 'border-box',
        position: 'relative',
      }}
    >
      <Typography
        variant="h6"
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: '10px',
        }}
      >
        {name}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '40px',
          width: '100%',
        }}
      >
        {data.map((bar, index) => (
          <div
            key={index}
            style={{
              flex: '1',
              height: '100%',
              backgroundColor: bar.color,
              margin: '0 1px',
              position: 'relative',
              cursor: bar.date !== 'No Incident' ? 'pointer' : 'default', // Conditional cursor style
            }}
            onMouseEnter={() => setHoveredBar(index)}
            onMouseLeave={() => setHoveredBar(null)}
            onClick={() => {
              if (bar.date !== 'No Incident') {
                const element = document.getElementById(bar.incidentId);
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                  setHighlightActive(true);
                  element.classList.add('elementWithGradient', 'fade-in');
                  setTimeout(() => {
                    element.classList.remove('fade-in');
                    setHighlightActive(false);
                  }, 4000);
                }
              }
            }}
          >
            {hoveredBar === index && (
              <div
                ref={el => (tooltipRefs.current[index] = el)}
                style={{
                  position: 'absolute',
                  bottom: '100%',
                  left: '50%',
                  backgroundColor: 'rgba(0,0,0,0.8)',
                  color: 'white',
                  padding: '5px',
                  borderRadius: '4px',
                  whiteSpace: 'nowrap',
                  pointerEvents: 'none',
                }}
              >
                {bar.date !== 'No Incident'
                  ? `Date: ${bar.date}, Severity: ${bar.severity}`
                  : 'No Incident'}
              </div>
            )}
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
          fontSize: '14px',
          width: '100%',
          position: 'relative',
        }}
      >
        <span style={{ flexGrow: 0 }}>100 days ago</span>
        <span
          style={{
            flex: '1',
            borderTop: '1px solid #ccc',
            position: 'relative',
          }}
        >
          <span
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: '#fff',
              padding: '0 10px',
            }}
          >
            {uptime}% uptime
          </span>
        </span>
        <span style={{ flexGrow: 0 }}>Today</span>
      </div>
    </div>
  );
};

export default HealthChart;
