import React from 'react';
import { Incident } from '../types/types';
import { Card, Typography } from '@mui/material';

interface ApplicationStatus {
  application: string;
  description: string;
  status: string; // Derived status based on the latest incident
  latestIncidentDate: Date | null;
}

interface Props {
  incidents: Incident[];
}

function mapStatusToHealth(status: string): string {
  switch (status) {
    case 'Resolved':
      return 'Healthy';
    case 'Identified':
    case 'Investigating':
      return 'Recovering';
    case 'Open':
      return 'Unhealthy';
    default:
      return 'Unknown'; // Handle unexpected status
  }
}

function getBackgroundColor(status: string): string {
  switch (status) {
    case 'Healthy':
      return '#BFEDE6'; // Very light green
    case 'Recovering':
      return '#fffde7'; // Very light yellow
    case 'Unhealthy':
      return '#ffebee'; // Very light red
    default:
      return '#eeeeee'; // Light grey for unknown statuses
  }
}

function getTextColor(status: string): string {
  switch (status) {
    case 'Healthy':
      return '#1b5e20'; // Dark green for very light green background
    case 'Recovering':
      return '#827717'; // Dark golden for very light yellow background
    case 'Unhealthy':
      return '#b71c1c'; // Dark red for very light red background
    default:
      return '#424242'; // Charcoal for light grey background
  }
}

const ApplicationHealthStatus: React.FC<Props> = ({ incidents }) => {
  const applications: ApplicationStatus[] = [
    {
      application: 'Dream',
      description: 'Asset Management and Repair Ticket Software',
      status: 'Healthy',
      latestIncidentDate: null,
    },
    {
      application: 'Google Telemetry Services',
      description: 'Google Admin Console and Telemetry management',
      status: 'Healthy',
      latestIncidentDate: null,
    },
    {
      application: 'Vivacity Integrations',
      description: 'Punchout, ASN, Netsuite Connections, and others',
      status: 'Healthy',
      latestIncidentDate: null,
    },
  ];

  // Update applications with the latest incident status
  incidents.forEach(incident => {
    const app = applications.find(a => a.application === incident.application);
    if (app) {
      const incidentDate = new Date(incident.creation_date);
      if (!app.latestIncidentDate || app.latestIncidentDate < incidentDate) {
        app.status = mapStatusToHealth(incident.status);
        app.latestIncidentDate = incidentDate;
      }
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        padding: '20px',
        gap: '20px',
      }}
    >
      {applications.map((app, index) => (
        <Card
          key={index}
          style={{
            flex: '1 1 300px',
            margin: '10px',
            backgroundColor: getBackgroundColor(app.status),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <Typography variant="h6" style={{ marginBottom: '10px' }}>
            {app.application}
          </Typography>
          <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>
            {app.description}
          </Typography>
          <Typography
            variant="body2"
            style={{ color: getTextColor(app.status) }}
          >
            Status: {app.status}
          </Typography>
        </Card>
      ))}
    </div>
  );
};

export default ApplicationHealthStatus;
