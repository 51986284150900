import React from 'react';

const Header = () => {
  return (
    <header
      style={{
        background: 'linear-gradient(90deg, #9400d3, #24437c)',
        color: 'white',
        padding: '10px 20px', // Adds padding
        textAlign: 'center',
        width: '100%',
        boxSizing: 'border-box', // Ensures padding does not add to width
      }}
    >
      <a
        href="https://vivacitytech.com/"
        className="header-logo-link"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <img
          src="https://cdn11.bigcommerce.com/s-rclih4lkis/images/stencil/original/vivacity_tech_logo_1684164774__34423.original.png"
          alt="Vivacity Tech PBC"
          style={{ height: 'auto', maxWidth: '100%', maxHeight: '60px' }} // Responsive sizing
        />
      </a>
    </header>
  );
};

export default Header;
