// App.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import IncidentList from './components/IncidentList';
import ApplicationHealthStatus from './components/ApplicationHealthStatus';
import { CssBaseline } from '@mui/material';
import { Incident } from './types/types';
import Header from './components/Header';
import HealthChartList from './components/HealthChartList';
import { Typography } from '@mui/material';
import './App.css';

const App: React.FC = () => {
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [highlightActive, setHighlightActive] = useState(false);

  useEffect(() => {
    axios
      .get<Incident[]>('incidents.json')
      .then(response => {
        setIncidents(response.data);
      })
      .catch(error => console.error('Error fetching incidents:', error));
  }, []);

  return (
    <div>
      <Header />
      <CssBaseline />
      <div style={{ margin: '20px' }}>
        <Typography variant="h3">Incident Dashboard</Typography>
        <ApplicationHealthStatus incidents={incidents} />
        <HealthChartList
          incidents={incidents}
          setHighlightActive={setHighlightActive}
        />
        <IncidentList incidents={incidents} />
      </div>
    </div>
  );
};

export default App;
