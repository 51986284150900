import React from 'react';
import { Incident as IncidentType } from '../types/types';
import Incident from './Incident';
import { Typography } from '@mui/material';

interface Props {
  incidents: IncidentType[];
}

const IncidentList: React.FC<Props> = ({ incidents }) => {
  const grouped = incidents.reduce(
    (acc, incident) => {
      const date = new Date(incident.creation_date).toISOString().split('T')[0]; // Same ISO format
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(incident);
      return acc;
    },
    {} as { [key: string]: IncidentType[] }
  );

  const sortedDates = Object.keys(grouped).sort(
    (a, b) => new Date(b).getTime() - new Date(a).getTime()
  );

  return (
    <div>
      <Typography variant="h4" style={{ marginTop: '15px' }}>
        Incident List
      </Typography>
      {sortedDates.map(date => (
        <div key={date} id={date}>
          <Typography variant="h5">{date}</Typography>
          <div style={{ padding: '0 20px 20px 20px' }}>
            {grouped[date].map((incident, index) => (
              <Incident key={index} incident={incident} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default IncidentList;
