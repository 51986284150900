import React from 'react';
import { Incident as IncidentType } from '../types/types';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';

interface Props {
  incident: IncidentType;
}

const statusColor: { [key: string]: string } = {
  Open: '#f44336', // Red
  Investigating: '#e65100', // Orange
  Identified: '#c6a700', // Yellow
  Resolved: '#40c9b5', // Green
};

const severityColor: { [key: string]: string } = {
  Critical: '#f44336',
  High: '#e65100',
  Moderate: '#c6a700',
  Low: '#4caf50',
  Informational: '#2196f3',
};

const Incident: React.FC<Props> = ({ incident }) => {
  const timezone = ' (MT)';
  const isResolved = incident.status === 'Resolved';
  const anticipated_resolution_date = incident.anticipated_resolution_date
    ? new Date(incident.anticipated_resolution_date).toLocaleTimeString() +
      timezone
    : '';

  return (
    <Card
      style={{
        marginBottom: '20px',
        backgroundColor: isResolved ? '#ffffff' : '#fff',
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          style={{
            backgroundColor:
              statusColor[incident.status as keyof typeof statusColor] ||
              '#000',
            color: '#fff',
            padding: '10px',
          }}
        >
          {incident.application} - {incident.status}
        </Typography>
        <Typography variant="h6">Information</Typography>

        <Typography sx={{ mb: 1.5 }}>
          Description: {incident.description}
        </Typography>
        <Typography variant="body2">
          Severity:{' '}
          <span
            style={{
              color:
                severityColor[incident.severity as keyof typeof severityColor],
            }}
          >
            {incident.severity}
          </span>
        </Typography>
        {anticipated_resolution_date && isResolved === false && (
          <Typography variant="body2">
            Anticipated Resolution Time: {anticipated_resolution_date}
          </Typography>
        )}
        <br />
        <Typography variant="h6">Status Updates</Typography>
        <List dense>
          {incident.updates
            .slice()
            .reverse()
            .map((update, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={`${
                      new Date(update.update_date).toLocaleString() + timezone
                    }: ${update.update_description}`}
                    secondary={
                      update.status_changed_to
                        ? `Status changed to: ${update.status_changed_to}`
                        : ''
                    }
                  />
                </ListItem>
                {index < incident.updates.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Incident;
