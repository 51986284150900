import React from 'react';
import HealthChart from './HealthChart';
import { Incident } from '../types/types';

interface HealthChartListProps {
  incidents: Incident[];
  setHighlightActive: React.Dispatch<React.SetStateAction<boolean>>; // Ensure this is declared
}

const severityColors: { [key: string]: string } = {
  Critical: '#f44336',
  High: '#e65100',
  Moderate: '#c6a700',
  Low: '#4caf50',
  Informational: '#2196f3',
};

const HealthChartList: React.FC<HealthChartListProps> = ({
  incidents,
  setHighlightActive,
}) => {
  const applications = [
    'Dream',
    'Google Telemetry Services',
    'Vivacity Integrations',
  ];
  const today = new Date();
  const oneHundredDaysAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 99
  );

  const chartsData = applications.map(app => {
    const appIncidents = incidents.filter(
      incident => incident.application === app
    );
    let totalDowntimeMinutes = 0;

    appIncidents.forEach(incident => {
      const creationDate = new Date(incident.creation_date);
      let resolutionDate = incident.resolution_date
        ? new Date(incident.resolution_date)
        : new Date();

      if (creationDate < oneHundredDaysAgo)
        creationDate.setTime(oneHundredDaysAgo.getTime());
      if (resolutionDate > today) resolutionDate.setTime(today.getTime());

      if (creationDate < resolutionDate) {
        const downtime =
          (resolutionDate.getTime() - creationDate.getTime()) / 60000; // milliseconds to minutes
        totalDowntimeMinutes += downtime;
      }
    });

    const totalMinutes = 1440 * 100; // 100 days * 1440 minutes/day
    const uptimePercentage =
      ((totalMinutes - totalDowntimeMinutes) / totalMinutes) * 100;

    const data = Array.from({ length: 100 }).map((_, index) => {
      const checkDate = new Date(today);
      checkDate.setDate(today.getDate() - (99 - index));
      checkDate.setHours(0, 0, 0, 0); // Normalize checkDate to remove time component

      const found = appIncidents.find(incident => {
        const creationDate = new Date(incident.creation_date);
        creationDate.setHours(0, 0, 0, 0);
        return creationDate.toDateString() === checkDate.toDateString();
      });

      return {
        color: found ? severityColors[found.severity] : '#40c9b5',
        incidentId: found
          ? new Date(found.creation_date).toISOString().split('T')[0]
          : '',
        date: found
          ? new Date(found.creation_date).toLocaleDateString()
          : 'No Incident',
        severity: found ? found.severity : 'No Incident', // Ensure this line exists and is correct
      };
    });

    return {
      name: app,
      data,
      uptime: uptimePercentage.toFixed(2),
    };
  });

  return (
    <div style={{ width: '100%' }}>
      {chartsData.map((chart, index) => (
        <HealthChart
          key={index}
          name={chart.name}
          data={chart.data}
          uptime={chart.uptime}
          setHighlightActive={setHighlightActive} // Pass setHighlightActive to HealthChart
        />
      ))}
    </div>
  );
};

export default HealthChartList;
